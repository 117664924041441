<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined>
        <v-card-title class="orange-bg">
          <v-row>
            <v-col cols="12" md="3">
              <span class="font-weight-bold">Orange Report</span>
            </v-col>
            <v-col cols="12" md="3">
              Service Date: {{ formatDate(serviceDate) }}
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-end">
              <!-- <router-link class="blue--text" to="#"> -->
              <v-btn small @click="downloadPdf">
                Download pdf
              </v-btn>
              <!-- </router-link> -->
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- Start New Orange Reports section -->
          <v-container>
            <v-row>
              <v-col cols="12" md="9">
                <v-container>
                  <h6 class="font-weight-bold">Services Rendered</h6>
                  <v-row>
                    <v-col
                      cols="6"
                      sm="4"
                      md="3"
                      v-for="(service, i) in dbOrangeReportServices"
                      :key="'service_col_' + i"
                    >
                      <v-checkbox
                        readonly
                        v-model="renderedServices"
                        :label="service.name"
                        :value="service.name"
                        color="blue"
                        class="v-checkbox-no-margin"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <hr />
                  <v-row>
                    <v-col>
                      <h6 class="font-weight-bold">Visit Notes</h6>
                      <v-textarea
                        readonly
                        v-model="orangeReport.visitNotes"
                        solo
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <hr />
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col class="d-flex justify-start align-center"
                          ><h6 class="font-weight-bold">
                            Irrigation Systems Health
                          </h6>
                          <div class="ml-5">
                            <v-img
                              width="40"
                              height="40"
                              contain
                              :src="irrigationSystemHealthInfo.picture"
                            ></v-img>
                          </div>
                          <span class="ml-4">{{
                            irrigationSystemHealthInfo.name
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row v-if="formattedIrrigationInspectionDate">
                        <v-col>
                          <span class="v-label theme--light">
                            Your irrigation inspection is scheduled for
                            {{ formattedIrrigationInspectionDate }}
                          </span></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <hr />
                  <v-row>
                    <v-col>
                      <h6 class="font-weight-bold">
                        Resident Notepad
                      </h6>
                      <v-textarea
                        readonly
                        v-model="orangeReport.residentNotepad"
                        solo
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <hr />
                  <v-row>
                    <v-col>
                      <h6 class="font-weight-bold">
                        Quarterly Community Enhancement Plan
                      </h6>
                      <v-progress-linear
                        readonly
                        color="blue"
                        :value="orangeReport.ninetyDayPlanProgress"
                        height="25"
                      >
                        <template v-slot:default="{ value }">
                          <strong>{{ Math.ceil(value) }}%</strong>
                        </template></v-progress-linear
                      >
                      <v-row class="pt-2">
                        <v-col>
                          <span class="v-label theme--light"
                            >Quarterly Orange Walk
                            {{
                              quarterlyOrangeWalkDate
                                ? " is scheduled for " +
                                  formattedQuarterlyOrangeWalkDate
                                : " is not scheduled"
                            }}</span
                          ></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <hr />
                  <v-row>
                    <v-col>
                      <h6 class="font-weight-bold">
                        Follow Up / Actions*
                      </h6>
                      <v-textarea
                        readonly
                        v-model="orangeReport.followUpActions"
                        solo
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12" md="3">
                <v-container>
                  <v-row>
                    <v-col class="px-4 pb-n4" cols="12" md="12" sm="12">
                      <span class="text-h6 theme--light font-weight-bold"
                        >Photos</span
                      >
                      <v-row>
                        <v-col
                          cols="12"
                          v-for="(item, i) in orangeReport.photos.$values"
                          :key="'o' + i"
                        >
                          <div>
                            <a
                              href="#"
                              @click.prevent="showViewImageDialog(item)"
                              class="canEnlargeImage"
                            >
                              <v-img :src="item.fileUrl"></v-img>
                            </a>
                            <span>{{ item.description }}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>

          <!-- End Recent Orange Reports section -->
        </v-card-text>
        <v-card-actions>
          <!-- <router-link class="blue--text" to="#"> -->
          <v-btn @click="downloadPdf">Download pdf</v-btn>
          <!-- </router-link> -->
        </v-card-actions>
      </v-card>

      <v-dialog
        v-model="showImageDialog"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card tile>
          <v-toolbar flat dark class="orange-bg">
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showImageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-responsive :aspect-ratio="16 / 9">
            <v-card-text>
              <v-img
                :id="'preview_img_' + itemToView.id"
                aspect-ratio="1.7"
                min-height="600"
                contain
                position="center center"
                :src="itemToView.fileUrl"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span>{{ itemToView.description }}</span>
            </v-card-text>
          </v-responsive>

          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-dialog>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
// import IrrigationSystemHealthItem from "@/view/components/IrrigationSystemHealthItem.vue";

import {
  GET_QUERY,
  API_CUSTOMERS,
  API_ORANGE_REPORTS,
  API_ORANGE_REPORT_SERVICES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";

import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    relatedCommunity: null,
    relatedCommunityItems: [],
    serviceDate: null,
    formattedServiceDate: null,
    irrigationInspectionDate: null,
    formattedIrrigationInspectionDate: null,
    quarterlyOrangeWalkDate: null,
    formattedQuarterlyOrangeWalkDate: null,
    irrigationSystemStatusList: [
      "AllGoWithTheFlow",
      "NeedsAttention",
      "UnderRepair"
    ],
    orangeReport: {
      id: "",
      name: "",
      dateCreated: null,
      serviceDate: null,
      formattedServiceDate: null,
      mowService: true,
      edgeService: false,
      hedgesService: false,
      irrigationService: false,
      plantingService: false,
      weedControlService: false,
      debrisRemovalService: false,
      repairsService: false,
      fertilizationService: false,
      visitNotes: "",
      photos: [],
      irrigationSystemInspectionDate: null,
      irrigationSystemStatus: "AllGoWithTheFlow",
      residentNotes: "",
      ninetyDayPlanProgress: 0,
      ninetyDayPlanTrack: true,
      quarterlyOrangeWalkDate: null,
      actionsNotes: ""
    },
    options: {
      page: 1,
      itemsPerPage: 5
    },
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    dbOrangeReportServices: [],
    renderedServices: [],
    baseUrl: `${process.env.VUE_APP_DASHBOARD_URL}`,
    itemToView: {},
    showImageDialog: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    },
    reportId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    serviceDate: function() {
      this.formattedServiceDate = this.formatDate(this.serviceDate);
    },
    irrigationInspectionDate: function() {
      if (this.irrigationInspectionDate) {
        this.formattedIrrigationInspectionDate = this.formatDate(
          this.irrigationInspectionDate
        );
      }
    },
    quarterlyOrangeWalkDate: function() {
      this.formattedQuarterlyOrangeWalkDate = this.formatDate(
        this.quarterlyOrangeWalkDate
      );
    }
  },
  async mounted() {
    // this.loading = true;
    // await this.getComunityInfo();
    // await this.createBreadcrumb();
    // await this.setInternalBreadcrumbs();
    // this.getOrangeReport();
    // this.loading = false;
  },
  async created() {
    await this.getOrangeReportServices();
    await this.getComunityInfo();
  },
  methods: {
    showViewImageDialog(item) {
      this.itemToView = item;
      this.showImageDialog = true;
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },
    serviceStatusPromise() {
      return this.$store.dispatch(GET_QUERY, {
        listName: `${API_CUSTOMERS}/${this.communityInfo.aspireId}/serviceStatus`
      });
    },
    urlToBase64(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url;
        img.onload = () => {
          const canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL("image/jpeg"));
        };
        img.onerror = error => reject(error);
      });
    },
    downloadPdf() {
      let pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      let self = this;

      pdfMake.fonts = {
        // yourFontName: {
        //   normal: 'https://example.com/fonts/fontFile.ttf',
        //   bold: 'https://example.com/fonts/fontFile2.ttf',
        //   italics: 'https://example.com/fonts/fontFile3.ttf',
        //   bolditalics: 'https://example.com/fonts/fontFile4.ttf'
        // },
        Fontello: {
          normal: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
          bold: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
          italics: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
          bolditalics:
            self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf"
        },

        //download default Roboto font from cdnjs.com
        Roboto: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
        }

        // example of usage fonts in collection
        // PingFangSC: {
        //   normal: ['https://example.com/fonts/pingfang.ttc', 'PingFangSC-Regular'],
        //   bold: ['https://example.com/fonts/pingfang.ttc', 'PingFangSC-Semibold'],
        // }
      };

      let reportYear = new Date(this.orangeReport.serviceDate).getFullYear();
      let reportMonth = new Date(
        this.orangeReport.serviceDate
      ).toLocaleString("en-us", { month: "long" });
      let serviceDateModified = new Date(self.orangeReport.serviceDate)
        .toISOString()
        .substr(0, 10);

      const [, , day] = serviceDateModified.split("-");

      // let reportDay = new Date(
      //   this.orangeReport.serviceDate
      // ).toLocaleString("en-us", { day: "numeric" });

      let reportDay = day;

      let servicesRendered = this.orangeReport.servicesRendered.$values.map(
        item => item.name
      );

      let promisses = this.orangeReport.photos.$values.map(photo => {
        return this.urlToBase64(photo.fileUrl);
      });

      promisses.push(this.serviceStatusPromise());

      //  => icon-dot-circled
      const iconDotCircled = "";
      //  => icon-empty-circle
      const iconEmptyCircle = "";

      Promise.allSettled(promisses).then(function(res) {
        // let newArray = res;
        let length = res.length;

        let serviceStatus = res[length - 1].value.data;

        res.splice(length - 1, 1);
        let reportImages = res.map(res => {
          return {
            image: res.value, //this.dataUrl,//"sidebar", //photo.fileUrl,
            style: "photos",
            width: 100,
            height: 60
          };
        });

        let docDefinition = {
          images: {
            ynLogo: self.baseUrl + "/media/logos/logo-light.png",
            sidebar: self.baseUrl + "/media/assets/aside-bg.jpg",
            "sentiment-0.0":
              self.baseUrl + "/media/assets/orange-report/Leaf_0.0.png",
            "sentiment-0.5":
              self.baseUrl + "/media/assets/orange-report/Leaf_0.5.png",
            "sentiment-1.0":
              self.baseUrl + "/media/assets/orange-report/Leaf_1.0.png",
            "sentiment-1.5":
              self.baseUrl + "/media/assets/orange-report/Leaf_1.5.png",
            "sentiment-2.0":
              self.baseUrl + "/media/assets/orange-report/Leaf_2.0.png",
            "sentiment-2.5":
              self.baseUrl + "/media/assets/orange-report/Leaf_2.5.png",
            "sentiment-3.0":
              self.baseUrl + "/media/assets/orange-report/Leaf_3.0.png",
            "sentiment-3.5":
              self.baseUrl + "/media/assets/orange-report/Leaf_3.5.png",
            "sentiment-4.0":
              self.baseUrl + "/media/assets/orange-report/Leaf_4.0.png",
            "sentiment-4.5":
              self.baseUrl + "/media/assets/orange-report/Leaf_4.5.png",
            "sentiment-5.0":
              self.baseUrl + "/media/assets/orange-report/Leaf_5.0.png"
          },
          pageSize: "LETTER",
          pageMargins: [170, 40, 40, 40],
          background: function(currentPage, pageSize) {
            return [
              {
                canvas: [
                  {
                    type: "rect",
                    x: 0,
                    y: 0,
                    w: 150,
                    h: pageSize.height / 5,
                    color: "#E06521"
                  },
                  {
                    type: "rect",
                    x: 0,
                    y: pageSize.height / 5,
                    w: 150,
                    h: 5,
                    color: "#2DC1E4"
                  }
                ]
              },
              {
                image: "ynLogo",
                width: 120,
                absolutePosition: { x: 10, y: 15 }
              },
              {
                text: "ORANGE",
                absolutePosition: { x: 25, y: 67 },
                color: "white",
                fontSize: 20,
                bold: true,
                characterSpacing: -0.5
              },
              {
                text: "REPORT",
                absolutePosition: { x: 25, y: 85 },
                color: "black",
                fontSize: 20,
                bold: true,
                characterSpacing: 0.5
              },
              {
                text: `${reportMonth} ${reportDay}`,
                absolutePosition: { x: 25, y: 112 },
                color: "white",
                fontSize: 20,
                bold: true
              },
              {
                text: `${reportYear}`,
                absolutePosition: { x: 25, y: 130 },
                color: "black",
                fontSize: 20,
                bold: true
              },
              {
                image: "sidebar",
                width: 150,
                height: pageSize.height * (4 / 5) - 5
              }
            ];
          },
          content: [
            {
              columns: [
                {
                  // auto-sized columns have their widths based on their content
                  width: "auto",
                  text: "" //this.initials()
                },
                {
                  // star-sized columns fill the remaining space
                  // if there's more than one star-column, available width is divided equally
                  width: "*",
                  text: self.communityInfo.name.toUpperCase(),
                  fontSize: 15,
                  bold: true
                }
              ]
            },
            "\n\n",
            {
              columns: [
                {
                  // fixed width
                  width: "auto",
                  text: [
                    {
                      text:
                        self.communityInfo.propertyManager.fullName +
                        ", " +
                        self.communityInfo.propertyManager.role,
                      margin: 20
                    },
                    { text: "\n" + self.communityInfo.propertyManager.phone },
                    { text: " \n\nManaged by:\n", bold: true },
                    { text: self.communityInfo.managementCompany.name + "\n" },
                    {
                      text:
                        self.communityInfo.managementCompany.address.line1 +
                        "\n"
                    },
                    {
                      text:
                        self.communityInfo.managementCompany.address.city +
                        ",  " +
                        self.communityInfo.managementCompany.address.stateCode +
                        " " +
                        self.communityInfo.managementCompany.address.postalCode
                    }
                  ]
                },
                {
                  // percentage width
                  width: "*",
                  stack: [
                    {
                      text: "Community Landscape Quality Sentiment",
                      bold: true
                    },
                    {
                      image:
                        "sentiment-" +
                        new Number(self.communityInfo.sentiment.value).toFixed(
                          1
                        ),
                      width: 120,
                      style: "my5"
                    },
                    {
                      text: self.communityInfo.sentiment.value + " / 5",
                      bold: true,
                      fontSize: 15
                    }
                  ],
                  alignment: "center"
                },
                {
                  // percentage width
                  width: "auto",
                  text: [
                    { text: "Service Status", bold: true },
                    "\n\n" + serviceStatus.status
                  ],
                  alignment: "center"
                }
              ],
              columnGap: 10
            },
            "\n\n",
            {
              columns: [
                {
                  // auto-sized columns have their widths based on their content
                  width: "auto",
                  stack: [
                    {
                      text: "Service Requests This Period",
                      bold: true,
                      style: "mb5"
                    },
                    {
                      columns: [
                        {
                          width: "*",
                          stack: [
                            { text: "5", fontSize: 16, bold: true },
                            "Open Tasks"
                          ],
                          alignment: "center"
                        },
                        {
                          width: "*",
                          stack: [
                            { text: "2", fontSize: 16, bold: true },
                            "Closed"
                          ],
                          alignment: "center"
                        }
                      ]
                    }
                  ]
                },
                {
                  // star-sized columns fill the remaining space
                  // if there's more than one star-column, available width is divided equally
                  width: "*",
                  text: [
                    {
                      text:
                        "Quarterly Orange Walk " +
                        (self.quarterlyOrangeWalkDate
                          ? "is scheduled for " +
                            self.formattedQuarterlyOrangeWalkDate
                          : "is not scheduled"),
                      alignment: "center"
                    }
                  ]
                }
              ],
              // optional space between columns
              columnGap: 10
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      text: "",
                      border: [false, true, false, false],
                      borderColor: ["#E06521", "#E06521", "#E06521", "#E06521"]
                    }
                  ]
                ]
              },
              style: "mt10"
            },
            {
              layout: "noBorders",
              table: {
                widths: ["*", "*"],
                body: [
                  [
                    {
                      text: [
                        { text: "Branch Office: ", bold: true },
                        self.communityInfo.branch.name
                      ]
                    },
                    {
                      text: [
                        { text: "Account Manager: ", bold: true },
                        self.communityInfo.accountManager.fullName
                      ]
                    }
                  ]
                ]
              }
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      text: "",
                      border: [false, false, false, true],
                      borderColor: ["#E06521", "#E06521", "#E06521", "#E06521"]
                    }
                  ]
                ]
              },
              style: "mb10"
            },
            {
              layout: "noBorders",
              table: {
                widths: ["*", "100"],
                body: [
                  // body open
                  [
                    // first row open
                    [
                      // first column open
                      {
                        text: "Services Rendered This Period",
                        style: "heading"
                      },
                      {
                        table: {
                          body: [
                            [
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Mow") > -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Mow"
                                ]
                              },
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Edge") > -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Edge"
                                ]
                              },
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Hedge") > -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Hedge"
                                ]
                              },
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Irrigation") >
                                      -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Irrigation"
                                ]
                              }
                            ],
                            [
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Planting") > -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Planting"
                                ]
                              },
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Weed Control") >
                                      -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Weed Control"
                                ]
                              },
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf(
                                        "Debris Removal"
                                      ) > -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Debris Removal"
                                ]
                              },
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Repairs") > -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Repairs"
                                ]
                              }
                            ],
                            [
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf(
                                        "Fertilization"
                                      ) > -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Fertilization"
                                ]
                              },
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Bed Edging") >
                                      -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Bed Edging"
                                ]
                              },
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Hard Edging") >
                                      -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Hard Edging"
                                ]
                              },
                              {
                                text: [
                                  {
                                    text:
                                      servicesRendered.indexOf("Blowing") > -1
                                        ? iconDotCircled
                                        : iconEmptyCircle,
                                    style: "icon"
                                  },
                                  " Blowing"
                                ]
                              }
                            ]
                          ]
                        },
                        layout: "noBorders"
                      },
                      { text: "\n\nVisit Notes", style: "heading" },
                      { text: self.orangeReport.visitNotes },
                      {
                        text: [
                          {
                            text: "\n\nIrrigation System Health: ",
                            style: "heading"
                          },
                          self.irrigationSystemHealthInfo.name
                        ]
                      },
                      { text: "\n\nResident Notes", style: "heading" },
                      { text: self.orangeReport.residentNotepad },
                      {
                        text: "\n\nQuarterly Community Enhancement Plan",
                        style: "heading"
                      },
                      {
                        stack: [
                          {
                            canvas: [
                              {
                                type: "rect",
                                x: 0,
                                y: 0,
                                w: 180,
                                h: 15,
                                color: "#E7E7E8" // light gray
                              },
                              {
                                type: "rect",
                                x: 0,
                                y: 0,
                                w:
                                  180 *
                                  (self.orangeReport.ninetyDayPlanProgress /
                                    100),
                                h: 15,
                                color: "#97B73D" // green
                              }
                            ]
                          },
                          {
                            stack: [
                              {
                                text: "On Track",
                                style: { bold: true },
                                relativePosition: { x: 2, y: -12 }
                              },
                              {
                                text: "30",
                                relativePosition: { x: 60 - 12, y: -12 }
                              },
                              {
                                text: "60",
                                relativePosition: { x: 120 - 12, y: -12 }
                              },
                              {
                                text: "90",
                                relativePosition: { x: 180 - 12, y: -12 }
                              }
                            ]
                          }
                        ]
                      },
                      { text: "\n\nFollow Up / Actions", style: "heading" },
                      { text: self.orangeReport.followUpActions }
                    ], // first column close
                    reportImages
                    // [

                    //   // second column open
                    //   // { text: "Photos" },
                    //   {
                    //     image: "sidebar",
                    //     style: "photos",
                    //     width: 100,
                    //     height: 60
                    //   },
                    //   {
                    //     image: "sidebar",
                    //     style: "photos",
                    //     width: 100,
                    //     height: 60
                    //   },
                    //   {
                    //     image: "sidebar",
                    //     style: "photos",
                    //     width: 100,
                    //     height: 60
                    //   }
                    // ] // second column close
                  ] // first row close
                ] // body close
              }
            }
          ],
          defaultStyle: {
            fontSize: 8,
            bold: false
          },
          styles: {
            heading: {
              color: "#E06521",
              bold: true,
              margin: [0, 0, 0, 5]
            },
            icon: { font: "Fontello" },
            photos: {
              margin: [0, 10, 0, 10]
            },
            mt5: { margin: [0, 5, 0, 0] },
            mt10: { margin: [0, 10, 0, 0] },
            mb5: { margin: [0, 0, 0, 5] },
            mb10: { margin: [0, 0, 0, 10] },
            my5: { margin: [0, 5, 0, 5] },
            my10: { margin: [0, 10, 0, 10] }
          }
        };
        pdfMake.createPdf(docDefinition).open();
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();

            this.getOrangeReport();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async getOrangeReportServices() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_ORANGE_REPORT_SERVICES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.dbOrangeReportServices = response.data.$values;
            // this.renderedServices = this.dbOrangeReportServices.map(
            //   o => o.name
            // );
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async getOrangeReport() {
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_ORANGE_REPORTS,
          id: this.reportId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.orangeReport = response.data;
            this.renderedServices = this.orangeReport.servicesRendered.$values.map(
              s => s.name
            );
            this.serviceDate = new Date(this.orangeReport.serviceDate)
              .toISOString()
              .substr(0, 10);

            if (this.orangeReport.orangeWalkDate) {
              this.quarterlyOrangeWalkDate = new Date(
                this.orangeReport.orangeWalkDate
              )
                .toISOString()
                .substr(0, 10);
              this.formattedQuarterlyOrangeWalkDate = this.formatDate(
                this.quarterlyOrangeWalkDate
              );
            }

            this.irrigationInspectionDate = null;
            if (this.orangeReport.irrigationInspectionDate) {
              this.irrigationInspectionDate = new Date(
                this.orangeReport.irrigationInspectionDate
              )
                .toISOString()
                .substr(0, 10);
            }
            this.formattedIrrigationInspectionDate = null;
            if (this.irrigationInspectionDate) {
              this.formattedIrrigationInspectionDate = this.formatDate(
                this.irrigationInspectionDate
              );
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        {
          title: "Orange Reports",
          route: "/customerView/" + this.customerId + "/orange-reports"
        },
        { title: "View Orange Report" }
      ];
    },
    getServiceStatusIcon() {
      switch (this.communityInfo.serviceStatus.status) {
        case "On-Schedule":
          return "mdi-weather-sunny";
        case "Rain-Out":
          return "mdi-cloud";
        case "Holiday":
          return "mdi-flower";
        case "Conflict":
          return "mdi-alert";
      }
    },
    updateServiceStatus() {
      this.serviceStatusDialog = false;
      let statusStr = "";
      switch (this.serviceAlert.reason) {
        case 0:
          statusStr = "On-Schedule";
          break;
        case 1:
          statusStr = "Rain-Out";
          break;
        case 2:
          statusStr = "Holiday";
          break;
        case 3:
          statusStr = "Conflict";

          break;
      }
      const newStatus = {
        status: statusStr,
        rescheduleDate: this.serviceAlert.date
      };
      this.communityInfo.serviceStatus = newStatus;

      this.saveCustomer();
    },
    setStartingAfter() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
        this.endingBefore = "";
      }
    },
    setEndingBefore() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.endingBefore = this.userInvoices[0].id;
        this.startingAfter = "";
      }
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        {
          title: "Orange Reports",
          route: "/customerView/" + this.customerId + "/orange-reports"
        },
        { title: "View Orange Report" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    // formatDate(date) {
    //   return dateFormatter.formatEditDate(date);
    // },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    totalItems() {
      if (
        (this.userInvoices &&
          this.userInvoices.length === this.options.itemsPerPage &&
          this.previousPage <= this.options.page &&
          this.hasMore) ||
        (this.options.page === 1 &&
          this.userInvoices.length === this.options.itemsPerPage)
      ) {
        return (this.options.page + 1) * this.options.itemsPerPage;
      }
      return this.options.page * this.options.itemsPerPage;
    },
    irrigationSystemHealthInfo() {
      switch (this.orangeReport.irrigationSystemHealth) {
        case 0: //All Go With The Flow
          return {
            name: "All Go With The Flow",
            picture:
              "/media/assets/irrigation-system-health/icons-all-go-with-the-flow.png"
          };
        case 1: //Needs Attention
          return {
            name: "Needs Attention",
            picture:
              "/media/assets/irrigation-system-health/icons-needs-attention.png"
          };
        case 2: //Under Repair
          return {
            name: "Under Repair",
            picture:
              "/media/assets/irrigation-system-health/icons-under-repair.png"
          };

        default:
          return {
            name: "All Go With The Flow",
            picture:
              "/media/assets/irrigation-system-health/icons-all-go-with-the-flow.png"
          };
      }
    }
  }
};
</script>
